import React from 'react';
import Logo from '../../Header/Logo';
import { INLINE_MENU_SECTION_ITEMS } from './Individual.constants';


const CustomHeaderBody = () => (
  <div className="custom-header contentHome show moveTop">
    <div className="logo">
      <Logo />
    </div>
    <h2 className="menu-section-title">Besoin de badges supplémentaires ?</h2>
    <div className="inline-menu-section">
      {INLINE_MENU_SECTION_ITEMS.map(({ icon, iconAlt, text }) => (
        <div className="inline-menu-section-item">
          <div className="inline-menu-section-item-icon">
            <img src={icon} alt={iconAlt} />
          </div>
          <p className="inline-menu-section-item-text">
            {text}
          </p>
        </div>
      ))}
    </div>
  </div>
);

export default CustomHeaderBody;

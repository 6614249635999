import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';

const checkNumeric = (val) => isNumeric(val, { no_symbiols: true});

export const defaultValidation = (fieldVal = "") => fieldVal && fieldVal !== "false";

export const emailValidation = (emailVal = "") => isEmail(emailVal);

export const phoneValidation = (phoneVal = "") => checkNumeric(phoneVal) && phoneVal.length === 10;

export const zipValidation = (zipVal = "") => checkNumeric(zipVal) && zipVal.length === 5;

export const placesCountValidation = (placesCount = "") => checkNumeric(placesCount) && placesCount >= 50;

export const stationsCountValidation = (stationsCount = "") => checkNumeric(stationsCount);

export const badgeCountValidation = (stationsCount = "") => checkNumeric(stationsCount);


export const isValidationPassed = (fieldVal = "", extraValidation = null) => (
    extraValidation
        ? defaultValidation(fieldVal) && extraValidation(fieldVal)
        : defaultValidation(fieldVal)
);

export const validateSubmission = (fields, values) => {
    const data = {};

    const isMissingAnyField = fields.some(({ name, extraValidation, noValue, optional}) => {
        const fieldVal = values[name];
        if (noValue && !optional) {
            return false;
        }
        if (optional || isValidationPassed(fieldVal, extraValidation)) {
            data[name] = fieldVal;
            return false;
        }

        return true;
    });

    return !isMissingAnyField ? data : false;
};

import Input from '../components/Forms/_common/Fields/Input';
import Select from '../components/Forms/_common/Fields/Select';
import CheckBox from '../components/Forms/_common/Fields/CheckBox';
import Label from '../components/Forms/_common/Fields/Label';
import Copro from '../components/Forms/Copro';
import Copro50 from '../components/Forms/Copro50';
import Individual from '../components/Forms/Individual';
import Enterprise from '../components/Forms/Enterprise';
import Hotel from '../components/Forms/Hotel';
import { phoneValidation, emailValidation, zipValidation, placesCountValidation, stationsCountValidation } from './validation';

import coproBg from '../static/img/bg-copro.jpg';
import individualBg from '../static/img/bg-badge.jpg';
import enterpriseBg from '../static/img/bg-enterprise.jpg';
import hotelBg from '../static/img/bg-hotel.jpg';

// Workflows backend types values
export const COPRO_TYPE = "copro";
export const INDIVIDUAL_TYPE = "individual";
export const ENTERPRISE_TYPE = "enterprise";
export const HOTEL_TYPE = "hotel";
export const HOTEL_TYPE2 = "infra";
export const COPRO50_TYPE = "copro50";
export const SECOND_CONTACT = "form/:opp_id"

export const AUTRE = 'autre';
export const BANQUE_DES_TERRITOIRES = 'banque-des-territoires';

export const LOADING_BUTTON_TEXT = "Chargement...";
export const DONE_BUTTON_TEXT = "Terminer";

// Title
export const MONSIEUR = 'man';
export const MADAME = 'woman';

// Referer
export const PSA = 'PSA';

// YES-NO
export const OUI = 'oui';
export const NON = 'Non';

// Syndicat
export const FONCIA = 'foncia';
export const CENTURY21 = 'century21';
export const CITYA = 'citya'
export const IMMO_DE_FRANCE = 'immo-de-france'
export const LOISELETDAIGREMONT = 'loiselet-daigremont'
export const BELLMAN = 'bellman'

// Hotel
export const BEST_HOTEL = 'best-hotel'
export const FAST_HOTEL = 'fast-hotel'
export const CHOICE_HOTEL = 'choice-hotel'
export const LOGIS_DE_FRANCE = 'logis-de-france'
export const MERCURE = 'mercure'
export const BALLADINS = 'balladins'
export const NOVOTEL = 'novotel'
export const PULLMAN = 'pullman'
export const IBIS = 'ibis'
export const KYRIAD = 'kyriad'
export const TRINITY = 'trinity'


export const errorMsg = "Ce champ est manquant ou invalide";
export const errorMsgParking = "Ce champ est manquant ou invalide ou inferieur à 50"

export const ouiNonOptions = [
    {label: 'Oui', value: OUI},
    {label: 'Non' , value: NON}
];

export const syndicCoproVals = [
    {label: 'Century21', value: CENTURY21 },
    {label: 'Foncia', value: FONCIA },
    {label: 'Autre', value: AUTRE }
];
// export const syndicCopro50Vals = [
//     {label: 'Bellman', value: BELLMAN},
//     {label: 'Century21', value: CENTURY21},
//     {label: 'Citya', value: CITYA},
//     {label: 'Foncia', value: FONCIA},
//     {label: 'Immo de France', value: IMMO_DE_FRANCE},
//     {label: 'Loiselet & Daigremont', value: LOISELETDAIGREMONT},
//     {label: 'Autre', value: AUTRE}
// ];

export const referralVals = [
    {label: 'PSA', value: PSA},
    {label: 'Autre', value: AUTRE}
];
export const copro50ReferralVals = [
    {label: 'Banque Des Territoires', value: BANQUE_DES_TERRITOIRES },
    {label: 'Autre', value: AUTRE}
];

export const titleOptions = [
    {label: 'Monsieur', value: MONSIEUR},
    {label: 'Madame', value: MADAME}
];

export const hotels = [
    {label: 'Best Hotel', value: BEST_HOTEL},
    {label: 'Fast Hotel', value: FAST_HOTEL},
    {label: 'Choice Hotel', value: CHOICE_HOTEL},
    {label: 'Logis de France', value: LOGIS_DE_FRANCE},
    {label: 'Mercure', value: MERCURE},
    {label: 'Balladins', value: BALLADINS},
    {label: 'Novotel', value: NOVOTEL},
    {label: 'Pullman', value: PULLMAN},
    {label: 'Ibis', value: IBIS},
    {label: 'Kyriad', value: KYRIAD},
    {label: 'Trinity', value: TRINITY},
    {label: 'Autre', value: AUTRE}
];

export const defaultFormFields = [
    { Component: Select, label: 'Monsieur/Madame', options: titleOptions, name: 'civil', errorMsg },
    { Component: Input, type: 'text', label: 'Nom', name: 'last_name', errorMsg },
    { Component: Input, type: 'text', label: 'Prénom', name: 'first_name', errorMsg, pullRight: true },
    { Component: Input, type: 'phone', label: 'Numéro de téléphone', name: 'phone', extraValidation: phoneValidation, maxLength: 20, errorMsg: 'Le numéro de téléphone ne semble pas valide' },
    { Component: Input, type: 'email', label: 'Email', name: 'email', pullRight: true, extraValidation: emailValidation, errorMsg: 'Votre email ne semble pas valide' },
];

export const addressFormFields = [
    { Component: Input, type: 'text', label: 'Adresse', name: 'address', errorMsg },
    { Component: Input, type: 'text', label: 'Code Postal', name: 'zip', extraValidation: zipValidation, maxLength: 5, pullRight: true, errorMsg },
    { Component: Input, type: 'text', label: 'Ville', name: 'city', errorMsg },
];

export const agreement = { Component: CheckBox, label: 'J\'accepte que Zephyre me contacte pour mon projet d\'installation de bornes', name: 'agreement', pullRight: true, errorMsg }

export const agreement_badge = { Component: CheckBox, label: 'J\'accepte que Zephyre me contacte', name: 'agreement', pullRight: true, errorMsg }

export const secondStepFormFields = [
    { Component: Input, type: 'number', label: 'Combien de bornes souhaitez vous installer ?', name: 'charging_stations_count', errorMsg, extraValidation: stationsCountValidation, hide: (workflowType) => workflowType === COPRO50_TYPE },
    { Component: Input, type: 'number', label: 'Combien de places comporte votre parking ?', name: 'parking_place_count', errorMsg: errorMsgParking, extraValidation: placesCountValidation, hide: (workflowType) => workflowType !== COPRO50_TYPE },
    { Component: Input, type: 'date', label: 'QUELLE EST LA DATE DE LA PROCHAINE AG ?', name: 'date_next_ag', required: false, optional: true, hide: (workflowType) => workflowType !== COPRO50_TYPE, pullRight: true },
    { Component: Label, name: 'installation', label: 'Ou se situe l\'installation ?', noValue: true },
    ...addressFormFields,
];

// Workflows types objects
export const coproWorkflow = {
    route: COPRO_TYPE,
    label: "Copropriété",
    bg: coproBg,
    Component: Copro,
    visible: true
};
export const individualWorkflow = {
    route: 'badge',
    label: "Maison",
    bg: individualBg,
    Component: Individual,
    visible: true
};
export const copro50Workflow = {
    route: COPRO50_TYPE,
    label: "Copro de + de 50 places",
    bg: coproBg,
    Component: Copro50,
    visible: true
};
export const enterpriseWorkflow = {
    route: ENTERPRISE_TYPE,
    label: "Entreprise",
    bg: enterpriseBg,
    Component: Enterprise,
    visible: true
};
export const hotelWorkflow = {
    route: HOTEL_TYPE,
    label: "Hotel",
    bg: hotelBg ,
    Component: Hotel,
    visible: true
};

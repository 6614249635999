import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AppContext from '../../../state/AppContext';
import Input from '../_common/Fields/Input';
import Form from '../_common/Form';

import { AUTRE, referralVals, defaultFormFields, errorMsg, agreement_badge, INDIVIDUAL_TYPE, addressFormFields, LOADING_BUTTON_TEXT, DONE_BUTTON_TEXT, individualWorkflow} from '../../../utils/constants'
import { submitForm } from '../../../utils/submit';
import { badgeCountValidation } from '../../../utils/validation';
import CustomHeaderBody from './CustomHeaderBody';

const Individual = () => {
    const [data, setData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const urlQuery = new URLSearchParams(useLocation().search);
    const referrer = urlQuery.get("referrer");

    const { handleRouteChanging } = useContext(AppContext);

    const number_of_badge =  { Component: Input, type: 'number', label: 'Combien de badges souhaitez vous ?', name: 'badge_count', errorMsg, extraValidation: badgeCountValidation, pullRight: true }

    const note = { Component: Input, type: 'text', label: 'Note (Si vous souhaitez une facture veuillez entrer le nom et l\'adresse de l\'entreprise)', name: 'note', fullWidth:true, required: false, optional: true }

    const fields = [
        ...defaultFormFields,
        ...addressFormFields,
        number_of_badge,
        note,
    ];

    fields.push(agreement_badge);

    const formSubmissionData = {
        workflowType: INDIVIDUAL_TYPE, step: 1,
        fields, data, setIsSending, setIsSubmitted, setSubmissionError
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { handleRouteChanging(individualWorkflow.route, individualWorkflow.bg); }, []);

    return(
        <Form
            fields={fields}
            data={data}
            setData={setData}
            buttonLabel={isSending ? LOADING_BUTTON_TEXT : DONE_BUTTON_TEXT}
            submitForm={() => submitForm(formSubmissionData)}
            isSubmitted={isSubmitted}
            disabled={isSending}
            secondButton={false}
            submissionError={submissionError}
            onCloseModal={() => setSubmissionError(null)}
            CustomHeaderBody={CustomHeaderBody}
        />
    );
};

export default Individual;

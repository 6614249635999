import rfidIcon from '../../../static/img/icon/rfid.svg';
import currencyIcon from '../../../static/img/icon/currency.svg';
import thumbUpIcon from '../../../static/img/icon/thumb-up.svg';

export const INLINE_MENU_SECTION_ITEMS = [
  {
    icon: rfidIcon,
    text: '12€ TTC par badge',
    iconAlt: 'RFID icon'
  },
  {
    icon: currencyIcon,
    text: 'une seule facturation en fin de mois',
    iconAlt: 'Currency icon'
  },
  {
    icon: thumbUpIcon,
    text: 'utilisation des bornes facile et rapide',
    iconAlt: 'Thumb-up icon'
  },
]

import React from 'react';

const Footer = () => {

    return (
        <footer style={{textAlign: 'center', backgroundColor: '#f9f9f9', padding: 20}}>
            <p>
                Si vous souhaitez nous contacter,
                <a href="https://www.zephyre.fr/contact/"> cliquez ici</a>
            </p>
            <p>
            Pour toute interrogation concernant le traitement de ces informations par Zephyre, n’hésitez pas à contacter
                 <a href="mailto:vieprivee@zephyre.fr"> vieprivee@zephyre.fr</a>, notre Délégué à la Protection des Données sera heureux de vous répondre. <a href="https://www.zephyre.fr/vie-privee/">En Savoir plus</a>
            </p>
        </footer>
    );
};

export default Footer;

import React, { useContext, useEffect, useState } from 'react';

import AppContext from '../../../state/AppContext';
import Input from '../_common/Fields/Input';
import Select from '../_common/Fields/Select';
import Form from '../_common/Form';
import { hotels, defaultFormFields, hotelWorkflow, errorMsg, agreement, HOTEL_TYPE2, LOADING_BUTTON_TEXT, DONE_BUTTON_TEXT, AUTRE, addressFormFields} from '../../../utils/constants';
import { submitForm } from '../../../utils/submit';

const Hotel = () => {
    const [data, setData] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);

    const { handleRouteChanging } = useContext(AppContext);

    const fields = [
        ...defaultFormFields,
        { Component: Input, type: 'text', label: 'Nom de votre hotel', name: 'company_name', fullWidth: true, errorMsg },
        { Component: Select, label: 'Quel est votre hotel ?', options: hotels, name: 'hotel', pullLeft: true, errorMsg },
    ];

    if (data.hotel === AUTRE ) {
        fields.push({ Component: Input, type: 'text', label: "Préciser votre chaine d'hôtel", name: 'hotel_other', pullRight: true, maxLength: 40, errorMsg })
    }

    fields.push(...addressFormFields);
    fields.push(agreement);


    const formSubmissionData = {
        workflowType: HOTEL_TYPE2, step: 1,
        fields, data, setIsSending, setIsSubmitted, setSubmissionError
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => { handleRouteChanging(hotelWorkflow.route, hotelWorkflow.bg); }, []);

    return(
        <Form
            fields={fields}
            data={data}
            setData={setData}
            buttonLabel={isSending ? LOADING_BUTTON_TEXT : DONE_BUTTON_TEXT}
            submitForm={() => submitForm(formSubmissionData)}
            isSubmitted={isSubmitted}
            disabled={isSending}
            secondButton={false}
            submissionError={submissionError}
            onCloseModal={() => setSubmissionError(null)}
        />
    );
};

export default Hotel;

import React, { useState } from 'react';

import Input from '../_common/Fields/Input';
import Select from '../_common/Fields/Select';
import RadioBox from '../_common/Fields/RadioBox';
import Form from '../_common/Form';

import { OUI, NON, AUTRE, hotels, errorMsg, ouiNonOptions, defaultFormFields, agreement} from '../../../utils/constants'
import { useLocation } from 'react-router-dom';
import { submitForm } from '../../../utils/submit';

const Enterprise = ({ nextStep, data, setData, workflowType }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [submissionError, setSubmissionError] = useState(null);
    const urlQuery = new URLSearchParams(useLocation().search);
    const hotel = urlQuery.get("hotel");
    const isHotel = parseInt(urlQuery.get("is_hotel"));

    const fields = [
        ...defaultFormFields,
        { Component: Input, type: 'text', label: 'Nom de votre entreprise', name: 'company_name', fullWidth: true, errorMsg },
    ];

    if(hotel && hotels.map(r => r.value.toLowerCase()).includes(hotel.toLowerCase())) {
        data.referrer = `${hotel}-d`;
        data.is_hotel = OUI;
        data.hotel = hotel;
    }
    else {
        data.is_hotel = NON;
    }
    // else if (isHotel === 1 && !data.is_hotel) {
    //     fields.push({ Component: RadioBox, label: 'Je suis un hotelier', name: 'is_hotel', options: ouiNonOptions, checkedOption: 0, errorMsg });
    //     data.is_hotel = OUI;
    // } else {
    //     fields.push({ Component: RadioBox, label: 'Je suis un hotelier', name: 'is_hotel', options: ouiNonOptions, errorMsg });
    // }

    // if (data.is_hotel === OUI && ! hotel) {
    //     fields.push({ Component: Select, label: 'Quel est votre hotel ?', options: hotels, name: 'hotel', pullRight: true, errorMsg })
    // }

    // if (data.hotel === AUTRE ) {
    //     fields.push({ Component: Input, type: 'text', label: "Préciser votre chaine d'hôtel", name: 'hotel_other', pullRight: true, maxLength: 40, errorMsg })
    // }

    fields.push(agreement);

    const formSubmissionData = {
        step: 1,
        data, nextStep, workflowType, fields, setIsSending, setIsSubmitted, setSubmissionError
    };

    return(
        <Form
            fields={fields}
            data={data}
            setData={setData}
            submitForm={() => submitForm(formSubmissionData)}
            isSubmitted={isSubmitted}
            disabled={isSending}
            submissionError={submissionError}
            onCloseModal={() => setSubmissionError(null)}
        />
    );
};

export default Enterprise;
